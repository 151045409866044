<template>
  <v-container>
    <v-row>
      <v-col cols="12"
        md="11">
        <h1>Custom Audience</h1>
      </v-col>
    </v-row>
    <v-card class="mt-5">
      <v-card-title>Create Custom Audience</v-card-title>
      <v-divider />
      <v-card-text>
        <import-excel :audienceData="audiences" />
      </v-card-text>
      <v-divider />
      <h3 class="ma-3">Join Audiences</h3>
      <v-card-actions>
        <div class="d-flex justify-start mb-6">
          <v-select
            v-model="audiences.join"
            class="mr-2"
            label="Join Audience"
            :items="joinAudience"
            outlined
            dense />
          <v-select
            v-model="audiences.masterField"
            class="mr-2"
            label="A Field Name"
            :items="fieldAudince"
            outlined
            dense />
          <v-select
            v-model="audiences.audience"
            class="mr-2"
            label="Audience Name"
            :items="GetAudienceList.audiences"
            item-text="name"
            item-value="name"
            outlined
            dense />
          <v-select
            v-model="audiences.audienceField"
            class="mr-2" 
            label="B Field Name"
            :items="fieldAudince"
            outlined
            dense />
          <v-btn 
            @click="createJob"
            class="mr-2" 
            color="primary"
            outlined> Create Job </v-btn>
          <v-btn 
            @click="exportAudiences"
            color="deep-purple darken-3"
            outlined> Export Cutomers List </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data () {
    return {
      joinAudience: [
        'A - B',
        'A + B',
        'A ∩ B'
      ],
      audiences: {
        conditions: [],
        audience: '',
        join: '',
        masterField: '',
        audienceField: ''
      },
      fieldAudince: [
        'DTAC_ID',
        'SUBR_ID',
        'CURR_SBSCRPN_ID',
        'MSISND'
      ]
    }
  },
  components: {
    importExcel: () => import('@/components/customAudience/importFileCustom.vue')
  },

  async created () {
    await this.getAudiences()
  },

  computed: {
    ...mapGetters({
      GetAudienceList: 'audiences/GET_AUDINCE',
    })
  },

  methods: {
    async getAudiences() {
      await this.$store.dispatch('audiences/GetAudince');
    },

    async exportAudiences() {
      await this.$store.dispatch('audiences/ExportCustomAudience', this.audiences);
    },

    async createJob() {
      await this.$store.dispatch('audiences/CreateJobAudiences', this.audiences)
      this.$router.push({ name: 'createJobAudience' });
    }
  }
}
</script>

<style>

</style>